import { Size } from 'hew/Avatar';
import Button from 'hew/Button';
import { useModal } from 'hew/Modal';
import Pivot, { TabItem } from 'hew/Pivot';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useTelemetry from 'analytics';
import Link from 'components/Link';
import Message from 'components/Message';
import { SingleOrg } from 'components/OrgPicker';
import Page from 'components/Page';
import Section from 'components/Section';
import useUI from 'components/UIManager';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchWithRetry } from 'hooks/useFetch';
import { paths } from 'routes/utils';
import { OrgRole } from 'saasTypes';
import { getOrgUserInvites } from 'services/api';
import * as GlobalApi from 'services/global-bindings';

import { _AddMemberModal } from './AddMemberModal';
import InvitesTabContent from './InvitesTabContent';
import MembersTabContent from './MembersTabContent';

enum PageTab {
  members = 'Members',
  invites = 'Invites',
}

const Users: React.FC = () => {
  const {
    orgState: { selectedOrg },
  } = useStore();
  const user = useUser();
  const { supportMatrix } = useStore();

  const { ui } = useUI();
  const AddMemberModal = useModal(_AddMemberModal);
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);

  // This ref ensures that we only send the tracking event once in useEffect
  const trackingSent = useRef(false);
  const { trackPage } = useTelemetry();

  useEffect(() => {
    if (!trackingSent.current) {
      trackPage();
      trackingSent.current = true;
    }
  }, [trackPage]);

  const isAdmin = useMemo(() => {
    return selectedOrg && user.roles[selectedOrg.id].role === OrgRole.Admin;
  }, [selectedOrg, user.roles]);

  const MembersTab: TabItem = {
    children: <MembersTabContent isAdmin={isAdmin || false} />,
    key: PageTab.members,
    label: PageTab.members,
  };

  const tabs = [MembersTab];

  /* Invites Tab logic  start. This is here because the Add Member button needs to call fetchInvitedUsers after it runs */
  const [invitedUsers, setInvitedUsers] = useState<GlobalApi.ModelInvitedUser[]>([]);

  const fetchInvitedUsers = useCallback(async () => {
    const invites = await fetchWithRetry(
      async () =>
        await getOrgUserInvites({ orgId: selectedOrg?.id || '' }, { signal: canceler.signal }),
    );
    setInvitedUsers(invites);
  }, [canceler.signal, fetchWithRetry, selectedOrg]);

  if (isAdmin && !supportMatrix?.glcpEnabled) {
    const InvitesTab: TabItem = {
      children: (
        <InvitesTabContent
          fetchInvitedUsers={fetchInvitedUsers}
          invitedUsers={invitedUsers}
          selectedOrgId={selectedOrg?.id || ''}
        />
      ),
      key: PageTab.invites,
      label: PageTab.invites,
    };
    tabs.push(InvitesTab);
  }
  /* Invites Tab logic  end */

  const onTabChange = (activeTab: PageTab) => {
    switch (activeTab) {
      case PageTab.members: {
        break;
      }
      case PageTab.invites: {
        break;
      }
    }
  };

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  if (!selectedOrg) return <Message title="Waiting for an organization selection.." />;

  return (
    <Page
      options={
        !supportMatrix?.glcpEnabled && (
          <Button disabled={!isAdmin} onClick={AddMemberModal.open}>
            New Member
          </Button>
        )
      }
      title="Members and Invitations">
      <Section title="Organization">
        <SingleOrg darkLight={ui.darkLight} displayId org={selectedOrg} size={Size.Large} />
      </Section>
      <Pivot
        defaultActiveKey={PageTab.members}
        items={tabs}
        tabBarExtraContent={
          <Link external={true} path={paths.docs('team-mgmt.html')} popout={true}>
            More on Roles
          </Link>
        }
        onChange={onTabChange as (tabKey: string) => void}
      />
      <AddMemberModal.Component orgId={selectedOrg.id} onClose={fetchInvitedUsers} />
    </Page>
  );
};

export default Users;
